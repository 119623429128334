/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export type UpdateDto = {
    name: string;
    email?: string;
    receiverName?: string;
    text: string;
    moment: UpdateDto.moment;
    video: Blob;
};
export namespace UpdateDto {
    export enum moment {
        BEFORE_UNPACKING = 'BEFORE_UNPACKING',
        AFTER_UNPACKING = 'AFTER_UNPACKING',
    }
}

