/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export { ApiError } from './core/ApiError';
export { CancelablePromise, CancelError } from './core/CancelablePromise';
export { OpenAPI } from './core/OpenAPI';
export type { OpenAPIConfig } from './core/OpenAPI';

export type { AuthBody } from './models/AuthBody';
export type { CommentDto } from './models/CommentDto';
export type { CreateGiftBody } from './models/CreateGiftBody';
export type { CreateGiftResponse } from './models/CreateGiftResponse';
export type { CreateUserBody } from './models/CreateUserBody';
export { Gift } from './models/Gift';
export type { RefreshBody } from './models/RefreshBody';
export type { SuccessResponse } from './models/SuccessResponse';
export type { TokenResponse } from './models/TokenResponse';
export { UpdateDto } from './models/UpdateDto';
export { UpdateTypeDto } from './models/UpdateTypeDto';
export { UserResponse } from './models/UserResponse';
export type { VerifyBody } from './models/VerifyBody';
export type { VerifyEmailBody } from './models/VerifyEmailBody';
export type { VerifyLoginBody } from './models/VerifyLoginBody';

export { AuthService } from './services/AuthService';
export { GiftService } from './services/GiftService';
export { UserService } from './services/UserService';
