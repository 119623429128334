/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export type UpdateTypeDto = {
    type: UpdateTypeDto.type;
};
export namespace UpdateTypeDto {
    export enum type {
        BUSINESS = 'BUSINESS',
        DIFFERENT = 'DIFFERENT',
        PRIVATE = 'PRIVATE',
        WEDDING = 'WEDDING',
    }
}

