const AT_KEY = 'GIFTI_AT';
const RT_KEY = 'GIFTI_RT';

export const clearTokens = () => {
  localStorage.removeItem(AT_KEY);
  localStorage.removeItem(RT_KEY);
};

export const saveTokens = (accessToken: string, refreshToken: string) => {
  localStorage.setItem(AT_KEY, accessToken);
  localStorage.setItem(RT_KEY, refreshToken);
};

export const getTokens = () => {
  const accessToken = localStorage.getItem(AT_KEY);
  const refreshToken = localStorage.getItem(RT_KEY);
  return { accessToken, refreshToken };
};
