/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { CreateUserBody } from '../models/CreateUserBody';
import type { SuccessResponse } from '../models/SuccessResponse';
import type { UserResponse } from '../models/UserResponse';
import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';
export class UserService {
    /**
     * @returns UserResponse
     * @throws ApiError
     */
    public static userControllerMe(): CancelablePromise<UserResponse> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/user/me',
        });
    }
    /**
     * @returns UserResponse
     * @throws ApiError
     */
    public static userControllerGetAllUsers(): CancelablePromise<Array<UserResponse>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/user',
        });
    }
    /**
     * @returns UserResponse
     * @throws ApiError
     */
    public static userControllerCreateUser({
        requestBody,
    }: {
        requestBody: CreateUserBody,
    }): CancelablePromise<UserResponse> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/user',
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * @returns SuccessResponse
     * @throws ApiError
     */
    public static userControllerDeleteUser({
        id,
    }: {
        id: string,
    }): CancelablePromise<SuccessResponse> {
        return __request(OpenAPI, {
            method: 'DELETE',
            url: '/user/{id}',
            path: {
                'id': id,
            },
        });
    }
}
