/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { AuthBody } from '../models/AuthBody';
import type { RefreshBody } from '../models/RefreshBody';
import type { SuccessResponse } from '../models/SuccessResponse';
import type { TokenResponse } from '../models/TokenResponse';
import type { VerifyEmailBody } from '../models/VerifyEmailBody';
import type { VerifyLoginBody } from '../models/VerifyLoginBody';
import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';
export class AuthService {
    /**
     * @returns SuccessResponse
     * @throws ApiError
     */
    public static authControllerLogin({
        requestBody,
    }: {
        requestBody: AuthBody,
    }): CancelablePromise<SuccessResponse> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/auth/login',
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * @returns TokenResponse
     * @throws ApiError
     */
    public static authControllerRefreshToken({
        requestBody,
    }: {
        requestBody: RefreshBody,
    }): CancelablePromise<TokenResponse> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/auth/refresh',
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * @returns TokenResponse
     * @throws ApiError
     */
    public static authControllerVerifyEmail({
        requestBody,
    }: {
        requestBody: VerifyEmailBody,
    }): CancelablePromise<TokenResponse> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/auth/verify-email',
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * @returns TokenResponse
     * @throws ApiError
     */
    public static authControllerVerifyLogin({
        requestBody,
    }: {
        requestBody: VerifyLoginBody,
    }): CancelablePromise<TokenResponse> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/auth/verify-login',
            body: requestBody,
            mediaType: 'application/json',
        });
    }
}
