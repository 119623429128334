import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import {
  AuthBody,
  AuthService,
  CommentDto,
  CreateUserBody,
  GiftService,
  OpenAPI,
  RefreshBody,
  UpdateDto,
  UpdateTypeDto,
  UserService,
  VerifyBody,
  VerifyEmailBody,
  VerifyLoginBody,
} from 'generated/api';
import { getTokens } from 'helpers/tokens';

OpenAPI.BASE = process.env.NEXT_PUBLIC_API_URL || '';
OpenAPI.TOKEN = async () => {
  if (typeof window === 'undefined') return '';
  return getTokens()?.accessToken || '';
};

// User
export const useMe = () => useQuery({ queryKey: [`useMe`], queryFn: UserService.userControllerMe }, undefined);

const USE_GET_ALL_USERS = 'useGetAllUsers';

export const useUsers = () =>
  useQuery({ queryKey: [USE_GET_ALL_USERS], queryFn: UserService.userControllerGetAllUsers });

export const useDeleteUserMutation = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: (id: string) => UserService.userControllerDeleteUser({ id }),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: [USE_GET_ALL_USERS] });
    },
  });
};

export const useCreateUserMutation = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: (data: CreateUserBody) => UserService.userControllerCreateUser({ requestBody: data }),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: [USE_GET_ALL_GIFTS] });
    },
  });
};

export const getMe = () => UserService.userControllerMe();

// Auth
export const useLoginMutation = () =>
  useMutation({
    mutationFn: (requestBody: AuthBody) => AuthService.authControllerLogin({ requestBody }),
  });

export const useVerifyLoginMutation = () =>
  useMutation({
    mutationFn: (requestBody: VerifyLoginBody) => AuthService.authControllerVerifyLogin({ requestBody }),
  });

export const useVerifyEmailMutation = () =>
  useMutation({
    mutationFn: (requestBody: VerifyEmailBody) => AuthService.authControllerVerifyEmail({ requestBody }),
  });

export const refreshTokens = (requestBody: RefreshBody) => AuthService.authControllerRefreshToken({ requestBody });

// Gift
export const useCreateGiftMutation = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: (amount?: number) => GiftService.giftControllerCreate({ requestBody: { amount: amount || 1 } }),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: [USE_GET_ALL_GIFTS] });
    },
  });
};

export const useUpdateGiftMutation = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: ({ id, formData }: { id: string; formData: UpdateDto }) =>
      GiftService.giftControllerUpdate({ id, formData }),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: [USE_GET_ALL_GIFTS] });
    },
  });
};

export const useUpdateGiftTypeMutation = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: ({ id, type }: { id: string; type: UpdateTypeDto['type'] }) =>
      GiftService.giftControllerUpdateType({ id, requestBody: { type } }),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: [USE_GET_ALL_GIFTS] });
    },
  });
};

const USE_GET_ALL_GIFTS = 'useGetAllGifts';

export const useGetAllGifts = () =>
  useQuery({ queryKey: [USE_GET_ALL_GIFTS], queryFn: () => GiftService.giftControllerGetAll() });

export const getGift = (id: string) => GiftService.giftControllerGet({ id });

export const exportGifts = (domain: string) => GiftService.giftControllerExport({ domain });

export const useVerifyGiftMutation = () =>
  useMutation({
    mutationFn: (requestBody: VerifyBody) => GiftService.giftControllerVerify({ requestBody }),
  });

const USE_GET_COMMENTS = 'useGetComments';

export const useGetComments = (id: string) =>
  useQuery({ queryKey: [USE_GET_COMMENTS, id], queryFn: () => GiftService.giftControllerGetComments({ id }) });

export const useSendCommentMutation = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: (requestBody: { id: string; body: CommentDto }) =>
      GiftService.giftControllerAddComment({ id: requestBody.id, requestBody: requestBody.body }),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: [USE_GET_COMMENTS] });
    },
  });
};

export const useDeleteGiftMutation = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: (id: string) => GiftService.giftControllerDelete({ id }),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: [USE_GET_ALL_GIFTS] });
    },
  });
};
