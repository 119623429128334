/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export type UserResponse = {
    role: UserResponse.role;
    uuid: string;
    email: string;
    name: string;
};
export namespace UserResponse {
    export enum role {
        ADMIN = 'ADMIN',
        USER = 'USER',
    }
}

